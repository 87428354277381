
                @use "@/scss/_mixins.scss" as *;
                


.outer-ring {
    border: 2px solid white;
    border-radius: 100%;
    padding: 3px;
}

.percent-text {
    font-size: 12px;
    color: white;
    line-height: 1;
}
