
                @use "@/scss/_mixins.scss" as *;
                

.picture-group {

    img {
        flex-shrink: 0;
        width: 100%;
    }

}
