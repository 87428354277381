
                @use "@/scss/_mixins.scss" as *;
                


.page-body {

    .page-body-content-container {
        position: relative;

        .page-body-item {
            padding: 15px 15px 15px 15px;

            flex: 1 0 auto;
            height: 100%;

            & > .v-tabs-items {
                background-color: unset;
            }
        }

    }

}

