
                @use "@/scss/_mixins.scss" as *;
                

.footer {
    border-top: 0.5px solid grey;
}

.footer-link {
    background: transparent !important;
    box-shadow: none;
    color: #1976d2;
}

.footer-icon {
    background: #1976d2;
}

.row {
    margin-top: 0;
    margin-bottom: 0;
}
