
                @use "@/scss/_mixins.scss" as *;
                


/* How and why. */

.quickbar-outer-wrapper {
    width: 80px;
    z-index: 99 !important;
    overflow: visible;
    box-shadow: rgba(0, 0, 0, 0.4) 1px 0 20px 2px;

    .quickbar-inner-wrapper {
        overflow-y: auto;

        .quickbar {
            position: fixed;
            left: 0;
            bottom: 0;
            top: 0;
            width: 80px;
            overflow: visible !important;

            &::v-deep .scroll-content {
                position: relative;
                z-index: 30;
            }
        }

    }

    &::v-deep .v-navigation-drawer__border {
        display: none;
    }

}
