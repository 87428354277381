
                @use "@/scss/_mixins.scss" as *;
                

.password-entry {

    input {
        border-right-width: 0px;
    }

    .reveal-password-btn {
        border-color: #ced4da !important;
        background-color: transparent !important;
        border-left-width: 0px;

        .loaded-svg {
            width: 24px;
            height: 24px;

            &:hover {
                opacity: 0.8;
            }
        }
    }

}
