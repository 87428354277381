
                @use "@/scss/_mixins.scss" as *;
                

.tab-button {

    &.icon-only {
        background-color: unset;
        border: 2px solid var(--highlight-colour);
        border-radius: 100%;
        transition: 0.2s border, 0.2s transform;

        &:hover, &.active {
            border-color: white;
            transform: scale(1.1);
        }

        .tab-label {
            padding-right: 6px;
            padding-left: 6px;
        }
    }

    background-color: var(--highlight-colour);
    height: 40px;
    max-height: 40px;
    margin-right: .75rem;
    border-radius: 1.2em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    color: white;

    &:hover .border, &:focus .border, &.active .border {
        border-color: white;
    }

    .border {
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border: 2px solid transparent;
        border-radius: 1.1em;
        transition: 0.2s border;
        z-index: 0;
    }

    &.has-icon {
        .tab-label {
            padding-right: 9px;
        }
    }

    .tab-label {
        padding-left: 15px;
        padding-right: 15px;

        display: flex;
        justify-content: center;
        align-items: center;
    }


}
