
                @use "@/scss/_mixins.scss" as *;
                

.supermenu-item {
    transition: 0.25s background-color, 0.25s color;
    max-height: 45px;

    .menu-icon-background {
        background-color: var(--hover-colour);
        border-radius: 50%;
        $size: 40px;
        width: $size;
        min-width: $size;
        height: $size;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover, &:focus {
        background-color: var(--hover-colour) !important;
        color: white;

        .menu-icon-background {
            background-color: white;
        }

        .menu-icon::v-deep path {
            fill: var(--hover-colour);
        }

        .label-text {
            color: white;
        }
    }

}
