
                @use "@/scss/_mixins.scss" as *;
                

.p-conv-overlay-main.overlay-active:before {
    backdrop-filter: blur(3px);
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
}
