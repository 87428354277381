
                @use "@/scss/_mixins.scss" as *;
                

.p-icon {
    fill: currentColor;

    * {
        width: 100%;
        height: 100%;
    }
}
