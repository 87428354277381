
                @use "@/scss/_mixins.scss" as *;
                

@keyframes breathe-1 {
    0% {
        width: 400%;
        left: -150%;
        bottom: -350%
    }
    50% {
        width: 200%;
        left: -50%;
        bottom: -340%;
    }
    100% {
        width: 400%;
        left: -150%;
        bottom: -350%;
    }
}

@keyframes breathe-2 {
    0% {
        width: 225%;
        bottom: -220%;
    }
    50% {
        width: 150%;
        bottom: -210%;
    }
    100% {
        width: 225%;
        bottom: -220%;
    }
}

.breathe {
    position: fixed;

    &.breathe-top {
        width: 100%;
        height: 100%;
        top: 0;
    }

    &.breathe-bottom {
        bottom: -350%;
        left: -150%;
        width: 400%;
        height: 400%;
        border-radius: 50%;

        &.active {
            animation-name: breathe-1;
            animation-duration: 10s;
            animation-iteration-count: infinite;
        }
    }

    &.breathe-middle {
        width: 225%;
        height: 300%;
        bottom: -220%;
        border-radius: 50%;

        &.active {
            animation-name: breathe-2;
            animation-duration: 8s;
            animation-iteration-count: infinite;
        }
    }

}

.breathe.breathe-middle, .breathe.breathe-middle {
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, .05);
}

#loading-pane {

    $z: 500000;

    @include full-absolute();
    position: fixed;
    z-index: $z;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;

    .pane-content {
        z-index: $z + 10;
        width: 50vw;

        @media(max-width: 900px) {
            width: 75vw;
        }
    }

}

.fade-loading-pane-enter-active {
    transition: opacity 0.5s;
}

.fade-loading-pane-leave-active {
    transition: opacity 0.5s 1.5s;
}

.fade-loading-pane-enter, .fade-loading-pane-leave-to {
    opacity: 0 !important;
}
