
                @use "@/scss/_mixins.scss" as *;
                


.tabs-desktop{
    display: none;
}

.tabs-mobile {
    display: flex;
    align-items: center;
}

@media screen and (min-width: 769px)
{
    .tabs-desktop{
        display: block;
    }

    .tabs-mobile{
        display: none;
    }
}

