
                @use "@/scss/_mixins.scss" as *;
                

.p-scroll-view {

    display: block;
    overflow: hidden;
    outline: none;

    &.no-mouse {
        overflow: auto !important;
    }

    .p-scroll-view-content {
        position: relative;
        display: block;
        height: auto;
    }

}
