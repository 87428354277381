
                @use "@/scss/_mixins.scss" as *;
                

.pt-overlay{
    backdrop-filter: blur(4px) saturate(0.5) grayscale(.5);
    width: 100%;
    height: 100%;
    z-index: 110;
    top: 0;
    left: 0;
    position: fixed;
}
