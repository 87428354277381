
                @use "@/scss/_mixins.scss" as *;
                


$content-margin: 15px;

.supermenu-section {

    .heading {
        margin-left: $content-margin;
        font-weight: 400;
        font-size: 1.5rem;
        margin-bottom: .5rem;
        @include rubik;
    }

    .items {
        margin: 0;
        padding: 0;
        position: relative;
        list-style: none;

        &:after {
            content: " ";
            pointer-events: none;
            @include full-absolute();
        }
    }
}
