
                @use "@/scss/_mixins.scss" as *;
                

.user-button-container {
    display: flex;
    align-items: center
}

.user-menu {

    img {
        max-height: 100%;
    }

    .user-name {
        font-weight: bold;
        color: white;
        font-size: .88rem;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

}
