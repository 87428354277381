
                @use "@/scss/_mixins.scss" as *;
                


.onboarding-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .slide-img {
        margin-bottom: 1rem;
        margin-top: 1rem;
        max-width: 100%;
        padding-left: 25px;
        padding-right: 25px;
    }
}

h1 {
    font-weight: 300;
    font-size: 32px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;

    @media(max-width: 500px) {
        font-size: 24px;
    }
}

h4 {
    font-size: 19px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;

    @media(max-width: 500px) {
        font-size: 15px;
    }
}
