
                @use "@/scss/_mixins.scss" as *;
                


#main-navigation {
    $nav-height: 60px;
    $nav-half-height: ($nav-height / 2);
    $hamburger-height: 14px;
    $hamburger-half-height: ($hamburger-height / 2);
    $hamburger-width: 24px;
    $hamburger-half-width: ($hamburger-width / 2);

    min-height: $nav-height !important;
    z-index: 107;

    .v-app-bar__nav-icon {
        color: white;
        margin-left: 0px;
        margin-right: 10px
    }

    .page-title {
        margin: 0 auto;
        color: white;
        font-size: 1.8rem;
        position: absolute;
        width: 500px;
        left: calc(50vw - 250px);
        text-align: center;
        @media(max-width: 992px) {
            display: none;
        }
    }

    .logo {
        $icon-width: 40px;
        $icon-half-width: 20px;
        width: 100px;
        fill: white;

        &.desktop::v-deep .p-logo {
            width: 120px;
        }

        @media(min-width: 991px) {
            &.desktop {
                display: block;
                margin-left: 5px;
            }
            &.mobile {
                display: none;
            }
        }

        @media(max-width: 991px) {
            &.desktop {
                display: none;
            }
            &.mobile {
                display: block;
                padding: 0;
                height: $icon-width;
                width: $icon-width;
                position: absolute;
                left: calc(50vw - #{$icon-half-width});
                top: $nav-half-height - $icon-half-width;
            }
        }
    }

    .menu-container {
        display: flex;
        align-items: center;

        .calendar-button {
            margin-right: 15px;
        }
    }

}
