
                @use "@/scss/_mixins.scss" as *;
                

.scrollable-indicator {
    bottom:1.5em;
    opacity:1;
    position:fixed;
    text-align:center;
    width:0;
    z-index: 3;
}
