
                @use "@/scss/_mixins.scss" as *;
                

.background {
    background-image: url(../../../../public/assets/images/maintenance_background.jpg);
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    & > h1 {
        font-size: 3.5em;
        text-align: center;
        line-height: 1.2;

        @media(max-width: 600px) {
            font-size: 2em;
        }
    }

    & > h3 {
        font-size: 2em;
        text-align: center;

        @media(max-width: 600px) {
            font-size: 1.5em;
        }
    }

    .timer {
        display: flex;
        width: 100%;
        justify-content: space-around;
        max-width: 700px;

        .timer-part {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
            text-align: center;

            .timer-value {
                font-size: 10em;
                margin-bottom: 0;
                line-height: 1;

                @media(max-width: 600px) {
                    font-size: 5em;
                }
            }

            .timer-label {
                font-size: 2em;
                margin-top: 0;

                @media(max-width: 600px) {
                    font-size: 1.2em;
                }
            }
        }
    }

    .social-container {
        display: flex;
        margin-top: 50px;
        justify-content: space-around;
        max-width: 200px;
        width: 100%;
    }

    .logo-icon {
        margin-top: 50px;
        min-width: unset;
        max-width: 500px;
        padding-left: 50px;
        padding-right: 50px;
    }
}
