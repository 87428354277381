
                @use "@/scss/_mixins.scss" as *;
                
/*!
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

// Shake animation from
// https://css-tricks.com/snippets/css/shake-css-keyframe-animation/

.shake {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.v-btn--active.no-active::before {
    opacity: 0 !important;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

a, button {
    outline: none;
}

.scrollbar-thumb {
    background-color: var(--highlight-colour) !important;
}

body {
    font-size: .88rem; /*DIFF TO BOOTSTRAP CORE*/
}

caption {
    padding-top: .55rem; /*DIFF TO BOOTSTRAP CORE*/
    padding-bottom: .55rem; /*DIFF TO BOOTSTRAP CORE*/
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 400; /*DIFF TO BOOTSTRAP CORE*/
}

.table th, .table td {
    padding: .55rem; /*DIFF TO BOOTSTRAP CORE*/
}

/* END BOOTSTRAP */

.table th, .table td {
    vertical-align: middle
}

legend {
    font-size: .88rem;
    font-weight: bold
}

.bench-nine {
    font-family: "BenchNine", sans-serif;
}

.rubik {
    font-family: "Rubik", sans-serif;

    &.uppercase {
        text-transform: uppercase;
    }

    &.r-light {
        font-weight: 200;
    }

}

.warning-text {
    font-weight: bolder;
    color: red;
}

html {
    --highlight-colour: #54ad3a;
}

.hidden {
    display: none;
}