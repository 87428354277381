
                @use "@/scss/_mixins.scss" as *;
                


$supermenu-width: 320px;

$supermenu-z: 180;

.supermenu-container {

    z-index: 5000 !important;
    overflow: hidden;

    #supermenu {
        left: 0;
        height: 100%;
        width: 100%;
        z-index: $supermenu-z;
        overflow-x: hidden;
        overflow-y: hidden;

        @media(max-width: 400px) {
            padding-bottom: 60px;
        }
    }

    &::v-deep .v-navigation-drawer__content {
        overflow: hidden !important;
    }

}
