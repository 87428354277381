
                @use "@/scss/_mixins.scss" as *;
                


.onboarding-dots {
    display: flex;

    .tab-icon {
        border: 2px solid var(--highlight-colour);
        border-radius: 100%;
        margin-left: 5px;

        & > * {
            width: 5px;
            height: 5px;
            background-color: transparent;
            margin: 2px;
            border-radius: 100%
        }

        &.active {
            & > * {
                background-color: var(--highlight-colour);
            }
        }
    }
}
