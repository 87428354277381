
                @use "@/scss/_mixins.scss" as *;
                

.p-logo {
    display: block;

    svg {
        display: block;
        overflow: visible;

        path {
            fill: var(--logo-colour);
        }
    }
}
