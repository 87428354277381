
                @use "@/scss/_mixins.scss" as *;
                

#feedback-modal {
    .modal-body {
        padding: 0;

        form {
            padding: 1rem;
        }
    }
}

.loading-cont {
    height: 100px;
    transition: height, padding-top, padding-bottom .5s;

    &.shrink {
        height: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow: hidden;
    }
}
