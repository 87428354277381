
                @use "@/scss/_mixins.scss" as *;
                

.tab-bar {
    position: sticky;
    left: 0;
    top: 64px;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 20px 2px;
    height: 60px;
    z-index: 98;

    .tab-bar-container {
        display: inline-flex;
        align-items: center;
        padding: 5px 15px 5px 15px;
        height: 60px;

        @media(max-width: 767px) {
            height: 47px;
        }
    }

    @media(max-width: 767px) {

        height: 47px;
    }

    @media (max-width: 960px) {
        top: 60px;
        border-bottom: var(--highlight-colour) solid 1.5px;
    }

    .tab-bar-container * {
        flex-shrink: 0;
    }

    .tab-bar-padder {
        width: 1px;
        height: 15px;
    }
}
