
                @use "@/scss/_mixins.scss" as *;
                


$content-margin: 15px;

.divider {
    border-top-width: 1px;
    border-top-color: var(--highlight-colour);
    margin: 1rem $content-margin;

    &.mini-divider {
        margin-top: 0;
    }
}
