
                @use "@/scss/_mixins.scss" as *;
                

.spinner {
    animation-name: rotation-easeOutBounce;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
}

@keyframes rotation-easeOutBounce {
    0% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(95deg);
    }

    85% {
        transform: rotate(87deg);
    }

    90% {
        transform: rotate(92.5deg);
    }

    95% {
        transform: rotate(89deg);
    }

    100% {
        transform: rotate(90deg);
    }


}
