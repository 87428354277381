
                @use "@/scss/_mixins.scss" as *;
                

.positive-transition {

    .body-flex {
        display: flex;
        min-height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }

    .action-snackbar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .v-icon {
            padding-right: 15px;
        }
    }
}
