
                @use "@/scss/_mixins.scss" as *;
                

.quickbar-item {
    width: 80px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.25s width, 0.25s background-color;
    background: var(--idle-colour);
    overflow-x: visible;
    box-sizing: border-box;
    height: 60px;
    z-index: 300;

    .text-container {
        display: none;
        font-size: 1.2rem;
        width: 100%;
        text-align: center;
        overflow: hidden;
        color: white;
        white-space: nowrap;
    }

    &:hover, &:focus {
        width: 250px;
        /*box-shadow: rgba(0, 0, 0, 0.4) 5px 15px 20px 2px;*/
        text-decoration: none !important;
        color: black !important;
        background-color: var(--hover-colour);
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;

        .icon-container {
            background-color: white;

            .item-icon::v-deep path {
                fill: var(--hover-colour);
            }
        }

        .text-container {
            display: unset;
            text-decoration: none;
            color: white;
        }
    }

    &.router-link-active .icon-container {
        position: relative;

        &:before {
            content: "";
            background: transparent;
            display: block;
            position: absolute;
            border: 2px solid white;
            border-radius: 50%;
            top: 2px;
            left: 2px;
            width: 46px;
            height: 46px;
        }
    }

    .icon-container {
        line-height: 1;
        border-radius: 50%;
        $size: 50px;
        width: $size;
        min-width: $size;
        height: $size;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--hover-colour);
    }
}
