
                @use "@/scss/_mixins.scss" as *;
                

.button-container {
    display: flex;
}

.title-container {
    flex-basis: 0;
    flex-grow: 0;
}

.avatar {
    box-shadow: #00000073 0px 0px 13px 3px;
    border-radius: 100% !important;
}

.supermenu-profile-link {
    .layout-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px 15px 5px;
    }
}
