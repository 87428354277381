
                @use "@/scss/_mixins.scss" as *;
                

.pt-progress-bar {
    position: relative;
    border: 2px solid var(--progress-colour);
    height: 30px;
    border-radius: 15em;
    text-align: center;

    &.progress-solid {
        border-color: #fff;
        background: #fff;
    }

    &.indeterminate {
        div {
            color: transparent !important;

            &:last-of-type {
                animation-duration: 2s;
                animation-name: indeterminate-bar;
                animation-iteration-count: infinite;
                animation-direction: normal;
                animation-timing-function: linear;
            }
        }
    }

    &:not(.indeterminate) {
        div {
            &:last-of-type {
                clip-path: inset(0 calc(100% - var(--progress-amt)) 0 0);
            }
        }
    }

    div {
        top: 2px;
        right: 2px;
        left: 2px;
        bottom: 2px;
        margin: 0;
        display: inline-block;
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 15em;
        transition: clip-path 0.2s ease;

        &:first-of-type {
            background-color: transparent;
            color: var(--progress-colour);
        }

        &:last-of-type {
            color: white;
            background-color: var(--progress-colour);
        }
    }
}

@keyframes indeterminate-bar {
    0% {
        clip-path: inset(0 100% 0 0%);
    }
    10% {
        clip-path: inset(0 60% 0 0%);
    }
    90% {
        clip-path: inset(0 0% 0 60%);
    }
    100% {
        clip-path: inset(0 0% 0 100%);
    }
}
