
                @use "@/scss/_mixins.scss" as *;
                

[data-push-onboarding="true"] .v-dialog__content {
    top: calc(100% - 85px);
    align-items: end;
}

.return-activator {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 50;
    cursor: pointer;
}

.slide-body {
    line-height: unset;
}

.action-button {

    cursor: pointer;
    bottom: 0;
    color: var(--highlight-colour);
    font-size: 20px;
    user-select: none;
    background: none;
    border: none;

    transition: transform 0.2s ease;

    &.next-button {
        right: 0;
        margin-right: 0.5rem;

        &:after {
            content: ">";
            font-weight: bold;
        }

        &:hover, &:focus {
            transform: translateX(5px);
        }
    }

    &.back-button {
        left: 0;
        margin-left: 0.5rem;
        opacity: 1;

        &:not(.visible) {
            opacity: 0;
        }

        &:before {
            content: "<";
            font-weight: bold;
        }

        &:hover, &:focus {
            transform: translateX(-5px);
        }
    }
}
