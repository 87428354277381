
                @use "@/scss/_mixins.scss" as *;
                

.quickbar-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 2px solid var(--highlight-colour);
}

.quickbar-section {

    &.no-divider {
        margin-top: 0.5rem;
    }

}
