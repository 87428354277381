
                @use "@/scss/_mixins.scss" as *;
                

.stepper-body {
    max-height: 600px;
    height: 100%;
    overflow-y: auto;

    &.is-mobile {
        max-height: unset;
    }
}
