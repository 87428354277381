
                @use "@/scss/_mixins.scss" as *;
                

.wait-card {

    padding: 25px;

    .wait-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}
